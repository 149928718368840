var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/tmp/build_60c4b399/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["TutorCruncher/static/js2/templates/branch_menu.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
if(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "data")) > 0) {
output += "\n  <div class=\"nav-item dropdown\">\n    <a class=\"nav-link dropdown-toggle show-toggle navbar-brand text-truncate w-100 align-middle\"\n       href=\"#\" id=\"branch-choice\" role=\"button\" data-toggle=\"dropdown\">\n      ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "current_branch"), env.opts.autoescape);
output += "\n    </a>\n    <div class=\"dropdown-menu\">\n      ";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "data");
if(t_3) {t_3 = runtime.fromIterator(t_3);
var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("branch", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n        ";
if(runtime.memberLookup((t_4),"type") == "switch-branch") {
output += "\n          <a class=\"dropdown-item\" data-method=\"POST\"\n             ";
if(runtime.memberLookup((t_4),"confirm")) {
output += "data-confirm=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"confirm"), env.opts.autoescape);
output += "\"\n             data-confirm-title=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"confirm_title"), env.opts.autoescape);
output += "\"";
;
}
output += "\n             href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"url"), env.opts.autoescape);
output += "\">\n            ";
output += runtime.suppressValue(runtime.memberLookup((t_4),"label"), env.opts.autoescape);
output += "\n          </a>\n        ";
;
}
else {
output += "\n          <a class=\"dropdown-item\"\n             ";
if(runtime.memberLookup((t_4),"mfa_enabled")) {
output += "data-toggle=\"crud-modal\"\n             ";
;
}
else {
output += "data-method=\"POST\" ";
;
}
output += "href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"url"), env.opts.autoescape);
output += "\">\n            ";
output += runtime.suppressValue(runtime.memberLookup((t_4),"label"), env.opts.autoescape);
output += "\n          </a>\n        ";
;
}
output += "\n      ";
;
}
}
frame = frame.pop();
output += "\n    </div>\n  </div>\n";
;
}
else {
output += "\n  <span class=\"navbar-brand text-truncate\" href=\"/\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "current_branch"), env.opts.autoescape);
output += "</span>\n";
;
}
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["TutorCruncher/static/js2/templates/branch_menu.njk"] , dependencies)